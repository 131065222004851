import { Spin } from "antd"

const LoadingCenter = ()=>{
    return (
        <div style={{
            width:"100vw",
            height:"100vh",
            background:"#00000033",
            position:"fixed",
            zIndex:100000,
            top:0,
            left:0,
            display:"flex",
            alignItems:"center",
            justifyContent:"center"
        }}>
            <Spin  size="large" spinning/>
        </div>
    )
}

export default LoadingCenter;