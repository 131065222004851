import React, { useState } from "react";
import LoadingCenter from "../assets/Loading";
import apis from "../assets/apis";
import { message } from "antd";

export default function ContactMe() {
  const [isLoading, setLoading] = useState(false);
  function submited(e) {
    e.preventDefault();
    const elm = e.target;
    const formData = new FormData(elm);
    try {
      setLoading(true);
      fetch(apis.contact, {
        method: "post",
        body:formData
      }).then(
        async x => {
          var resu = await x.json();
          resu.status ? message.success(resu.message) : message.error(resu.message);
        }
      ).catch(err => {
        message.error("Error processing request");
      })
    } catch (err) {
      message.error("Error processing request");
    }finally{
      setLoading(false);
    }
  }
  return (
    <>
      <section id="Contact" className="contact--section">
        {
          isLoading ? <LoadingCenter />:<></>
        }
        <div>
          <p className="sub--title">Get In Touch</p>
          <h2>Contact Me</h2>
          <p className="text-lg">
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. In, odit.
          </p>
        </div>
        <form className="contact--form--container" onSubmit={submited}>
          <div className="container">
            <label htmlFor="first-name" className="contact--label">
              <span className="text-md">First Name</span>
              <input
                type="text"
                className="contact--input text-md"
                name="fn"
                id="first-name"
                required
              />
            </label>
            <label htmlFor="last-name" className="contact--label">
              <span className="text-md">Last Name</span>
              <input
                type="text"
                className="contact--input text-md"
                name="ln"
                id="last-name"
                required
              />
            </label>
            <label htmlFor="email" className="contact--label">
              <span className="text-md">Email</span>
              <input
                type="email"
                className="contact--input text-md"
                name="email"
                id="email"
                required
              />
            </label>
            <label htmlFor="phone-number" className="contact--label">
              <span className="text-md">phone-number</span>
              <input
                type="number"
                className="contact--input text-md"
                name="phone"
                id="phone-number"
                required
              />
            </label>
          </div>
          <label htmlFor="choode-topic" className="contact--label">
            <span className="text-md">Choose a topic</span>
            <select id="choose-topic" name="topic" className="contact--input text-md">
              <option>Select One...</option>
              <option>Project Inquiry</option>
              <option>Technical Support</option>
              <option>General Questions </option>
              <option>Feedback  </option>
            </select>
          </label>
          <label htmlFor="message" className="contact--label">
            <span className="text-md">Message</span>
            <textarea
              className="contact--input text-md"
              id="message"
              rows="8"
              placeholder="Type your message..."
              name="mes"
              required
            />
          </label>
          <label htmlFor="checkboc" className="checkbox--label">
            <input type="checkbox" required name="checkbox" id="checkbox" />
            <span className="text-sm">I accept the terms</span>
          </label>
          <div>
            <button className="btn btn-primary contact--form--btn">Submit</button>
          </div>
        </form>
      </section>
    </>
  );
}